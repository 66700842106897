.imgInicioFOndo {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  color: #fff;
  font-size: 80px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
    text-align: center;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}




.containerBusnes{
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:unset;
  align-content:flex-start;
}

.item-00{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:auto;
  
}
      
.item-11{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  margin-top: 5px;
  width:auto;
}
      
.item-22{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:auto;
}

.item-0{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:30%;
  
}
      
.item-1{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  margin-top: 5px;
  width:60%;
}

 .serch{
   width: 30px !important;
 }



 .item-0Busnes{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:10%;
  margin-top: 5px;
  margin-right: 30px;


}
      
.item-10Busnes{
  order:1;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:43%;
  
}

.item-20Busnes{
  order:2;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  margin-top: 5px;
  width:18%;
  margin-right: 48px;

}

.contenedorConcept{
width: 80%;
margin: 0 auto; 
}
.RelacionarCenter{
margin: 0 auto;
}

/*CLase que aplica los estilos al elemento a aparecer*/
.show-element {
/*duracion de la animacion, puedes variar el tiempo, entre mas grande mas tarda el efecto*/
animation-duration: 2s;
animation-fill-mode: both;  cursor: pointer;
/*le indicas que animacion quieres aplicarle, animacion hecha por ti en un keyframe*/
animation-name: fadeIn;
background-color: rgb(233, 233, 233);
}

.show-element2 {
  /*duracion de la animacion, puedes variar el tiempo, entre mas grande mas tarda el efecto*/
  animation-duration: 2s;
  animation-fill-mode: both;  cursor: pointer;
  /*le indicas que animacion quieres aplicarle, animacion hecha por ti en un keyframe*/
  padding-left: 61%;
  padding-top: 6px ;
  animation-name: fadeIn;
  position:absolute;
  z-index: 20;
  
  }
  @media (max-width:1380px) {
    .show-element2 {
      animation-duration: 2s;
      animation-fill-mode: both;  cursor: pointer;
      /*le indicas que animacion quieres aplicarle, animacion hecha por ti en un keyframe*/
      padding-left: 65.3%;
      padding-top: 6px ;
      animation-name: fadeIn;
      position:absolute;
      z-index: 20;
      
     }
  
    
  
   }



.divBusnes{
  position: relative;
}
.hide-element {
animation-duration: 2s;
animation-fill-mode: both;
animation-name: fadeOut;
}

/*KeyFrames*/
/*La animacion hecha por ti, puedes ponerle cualquier nombre, solo es para identificarlo*/
@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

@keyframes fadeOut {
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
}

/*Puedes hacer mas animaciones personalizadas con todo lo que pueda hacer css*/
@keyframes otherAnimation {
0% {
  opacity: 0;
  transform: scale(0);
}
100% {
  opacity: 1;
  transform: scale(1);
}
}

@keyframes animationTranslateIn {
from {
  opacity: 0;
  transform: translateX(-200px);
}

to {
  opacity: 1;
  transform: translateX(0px);
}
}




/*Estilos table conceptos*/

.cabezeracolor{
color: white;
font-weight: bold;
font-size: 20px;
}

.unidadConcept{
text-align: center !important;
width: 100px;

}

.descripcionConcept{
width: 800px;
}

.cantidadConcept{
width: 100px;
text-align: center !important;
}

.precioConcept{
width: 150px;
text-align: center;

}

.impuestosConcept{

width: 250px;
}

.subtotalConcept{
width: 150px;

}

.totalConcept{
width: 150px;

}

.accionesConcept{
width: 60px;

}


.totalesCalculados{
background-color: rgb(220, 227, 227) !important;
color: black;
}

.borrarcl{
cursor: pointer;
width: 20px;
}

.tdunidad{
text-align: center;
}
.tddescripcion{
text-align: justify;
}
.tdprecio{
text-align: right;
}

.tdimpuestos{
text-align: center;
}
.tdsubtotal,.tdimpuestos{
text-align:right !important;
}
tbody tr:hover {background-color: rgb(228, 219, 219) ;}


.ContenedorT{
  display:flex;
  flex-direction:row;
  flex-wrap:nowrap;
  justify-content:center;
  align-items:unset;
  align-content:flex-start;
}

.ContenedorT01{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:auto;
  padding-top: 8px;
}
      
.ContenedorT02{
  order:0;
  flex:0 1 auto;
  align-self:auto;
  height:auto;
  width:auto;
}
      
.TypeRelacion{
  float: right;
  padding-top: 40px;
  padding-right: 230px;
}

