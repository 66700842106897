html{
    scroll-behavior: smooth;
}

body{
    font-family: 'PT Sans', sans-serif;
        margin: 0;
}

h1{ font-size: 3.5em;}
h2{ font-size: 2.7em;}
h3{ font-size: 2em;}


.container{
    max-width: 1400px;
    margin: auto;
}




#hero{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    flex-direction: column;
    height: 90vh;
    background-image: linear-gradient(
        0deg,
        rgba(0,0,0,0.5),
        rgba(0,0,0,0.5)
    )
  
}

#hero h1{
    color: white;
}

#hero button{
    font-size: 1.75em;
}

.content{
    background-color: white;

}
#somos-proya .container{
    text-align: justify;
    padding: 100px 12px;
}


#nuestrosServicios{
    padding-top: 100px;
}
#nuestros-programas{
    list-style: none;
    background-color:  #2e2e2e;
    color: white;
    text-align: justify;
    width: 100%;

}

#nuestros-programas h2{
    font-family: 'PT Sans', sans-serif;
    font-size: 10px;
    text-align: center;
}



#nuestros-programas .carta{
    background-position: center center;
    margin: 20px;
    border-radius: 15px;
}

.carta:first-child{
    background-image: linear-gradient(
        0deg,
        rgba(0,0,0,0.5),
        rgba(0,0,0,0.5)
    );

}

.carta:nth-child(2){
    background-image: linear-gradient(
        0deg,
        rgba(0,0,0,0.5),
        rgba(0,0,0,0.5)60
    );
}

.carta:nth-child(3){
    background-image: linear-gradient(
        0deg,
        rgba(0,0,0,0.5),
        rgba(0,0,0,0.5)
    );

}

#caracteristicas .container{
    text-align: justify;
    padding: 250px 12px;
}

#caracteristicas li{
    margin: 16px 0px;
    font-weight: bold;
}

@media (min-width: 850px){
    header{
        position: fixed;
        width: 100%;
    }

    header .container{
        flex-direction: row;
        justify-content: space-between;
    }

    header nav{
        flex-direction: row;
        padding-bottom: 0;
        padding-right: 20px;
    }

    #hero h1{
        font-size: 5em;
    }

    #somos-proya .container{
        display: flex;
        justify-content: space-evenly;
    }

    #somos-proya .texto{
        width: 50%;
        max-width: 600px;
        text-align: justify;
        padding-left: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #somos-proya h2{
        margin-top: 0;
    }

    #somos-proya .img-container{
         margin: 0 auto;
        background-size: cover;
  
    }

}


.cards {
    padding: 4rem;
    background: #fff;
  }
  
  h1 {
    text-align: center;
  }
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
    text-align: justify;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .cards__items2 {
    margin-bottom: 24px;
  }
  
  .cards__item2 {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  
  .cards__item2__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(255, 255, 255, 0.17);
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item2__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  .cards__item2__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  
  .cards__item2__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item2__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item2__info {
    padding: 20px 30px 30px;
  }
  
  .cards__item2__text {
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
  }
  
  .googleslides{
    width: 600px;
    height: 400px;
  }
  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items2 {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item2 {
      margin-bottom: 2rem;

    }
    .googleslides{
      width: 100%;
    
    }
  }




  .COntainerVideo{
    width: 100% !important;
    height: 100% !important;
  }

  
  